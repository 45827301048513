<template>
  <div>
    <div id="su-permissions-page">
      <div class="flex-column">
        <div class="flex-row">
          <h1 class="h1-title">George Report</h1>
          <button class="no-button-style margin-left-auto margin-right pointer-cursor" v-on:click="goToLogout()">
            Logout
          </button>
        </div>
        <div class="margin-top-2 flex-column margin-right-auto">
          <label class="text-field-title">Dealership ID</label>
          <input class="text-field" placeholder="Dealership ID" type="Number" v-model="reportDict.dealership" />
          <label class="text-field-title">Date</label>
          <input class="text-field" placeholder="Date" type="date" v-model="reportDict.date" />
          <label class="text-field-title margin-top-025">CSV</label>
          <input
            accept=".csv"
            type="file"
            class="margin-top-025 text-field"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
          />
          <div class="flex-row margin-top-025">
            <label class="text-field-title vertical-flex-center">Preview</label>
            <input
              v-model="reportDict.preview"
              class="horizontal-spacing-left vertical-flex-center"
              type="checkbox"
              id="traded"
            />
          </div>
          <primary-button v-if="!submitting" title="Submit" v-on:click="submit" class="margin-top-025" />
          <p v-else>Submitting</p>
        </div>

        <div class="results-table-container">
          <table class="results-table">
            <tr class="header">
              <th>dealership</th>
              <th>date time created</th>
              <th>car registration number</th>
              <th>car registration state</th>
              <th>customer name</th>
              <th>customer work phone</th>
              <th>customer email</th>
              <th>car chassis number</th>
              <th>car build date</th>
              <th>car compliance date</th>
              <th>car mileage</th>
              <th>car model year</th>
              <th>car make</th>
              <th>car model</th>
              <th>car colour</th>
              <th>car line</th>
              <th>car engine number</th>
              <th>car comments</th>
            </tr>
            <tr v-for="form in forms" v-bind:key="form" class="data-row">
              <td>{{ form.dealership.name }}</td>
              <td>{{ form.date_time_created }}</td>
              <td>{{ form.car_registration_number }}</td>
              <td>{{ form.car_registration_state }}</td>
              <td>{{ form.customer_name }}</td>
              <td>{{ form.customer_work_phone }}</td>
              <td>{{ form.customer_email }}</td>
              <td>{{ form.car_chassis_number }}</td>
              <td>{{ form.car_build_date }}</td>
              <td>{{ form.car_compliance_date }}</td>
              <td>{{ form.car_mileage }}</td>
              <td>{{ form.car_model_year }}</td>
              <td>{{ form.car_make }}</td>
              <td>{{ form.car_model }}</td>
              <td>{{ form.car_colour }}</td>
              <td>{{ form.car_line }}</td>
              <td>{{ form.car_engine_number }}</td>
              <td>{{ form.car_comments }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <error-modal v-if="error" :errors="[error]" @cancel="error = null" />
  </div>
</template>

<script>
import PrimaryButton from "../../components/Buttons/v2/PrimaryButton.vue";
import ErrorModal from "../../components/Modals/v2/ErrorModal.vue";
import { uploadGeorgeReport } from "../../api/Form.js";

export default {
  name: "SuperUserGeorgeReport",
  components: { PrimaryButton, ErrorModal },
  data: function () {
    return {
      submitting: false,
      forms: [],
      error: null,
      reportDict: {
        dealership: "",
        preview: null,
        file: null,
        date: "2023-01-01"
      }
    };
  },
  methods: {
    submit: function () {
      this.submitting = true;
      uploadGeorgeReport(this.reportDict)
        .then(response => {
          this.submitting = false;
          if (response["success"]) {
            console.log("success");
          } else {
            this.forms = response;
          }
        })
        .catch(error => {
          console.log(error.response);
          this.submitting = false;
          this.error = error;
        });
    },
    handleFileUpload: function () {
      this.reportDict.file = this.$refs.file.files[0];
    },
    goToLogout: function () {
      this.$router.push({ name: "Logout" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#su-permissions-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
  max-width: 100vw;
}
</style>
